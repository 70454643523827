import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Grid, Box, TextField, Button, Typography, Paper, useMediaQuery, CircularProgress, Divider, CssBaseline } from '@mui/material';
import { ColorlibStepIcon, ColorlibConnector } from '../components/utils';
import { useState } from 'react';
import { AttachMoneyOutlined, DownloadingOutlined, TaskAltOutlined } from '@mui/icons-material';
import SideMenu from '../components/SideMenu';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import HomeWebsite from './HomeWebsite';
import RetiradasKit from './RetiradasKit';
import Repasses from './Repasses';
import Discador from './Discador';
import AgenteVirtual from './AgenteVirtual';
import Contato from './Contato';
import '../components/Scrollbar.css';
import Perfil from './Perfil';

// Etapas do Stepper
const steps = [
  'Consulte seu CPF/CNPJ e veja se possui dívidas.',
  'Veja as opções de negociação e faça um acordo.',
  'Pronto! Agora é só pagar e voltar a ter crédito.',
];

const Home = () => {

  const isMobile = useMediaQuery('(max-width:899px)');
  const [loadingConsultar, setLoadingConsultar] = useState(false);
  const [loadingBoleto, setLoadingBoleto] = useState(false);
  const [loadingBaixarBoleto, setLoadingBaixarBoleto] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [logoutUpdt, setLogoutUpdate] = useState(0);
  const [value, setValue] = useState('');
  const [currentUpdate, setCurrentUpdate] = useState('');

  const colorPrimary = "30, 58, 138";
  const colorSecondary = "255, 153, 0";
  const colorTertiary = "171, 172, 186";

  const handleConsultar = () => {
    setLoadingConsultar(true)
    setTimeout(() => {
      setCurrentStep(1);
      setLoadingConsultar(false);
    }, 2000)
  }

  const handleGerarBoleto = () => {
    setLoadingBoleto(true)
    setTimeout(() => {
      setCurrentStep(2);
      setLoadingBoleto(false);
    }, 2000)
  }

  const handleBaixarBoleto = () => {
    setLoadingBaixarBoleto(true)
    setTimeout(() => {
      setLoadingBaixarBoleto(false);
    }, 2000)
  }

  const handleVoltarAoInicio = () => {
    setCurrentStep(0);
  }

  const renderContent = () => {
    switch (value) {
      case "home":
        return <HomeWebsite onChange={handleChange} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case "retiradas":
        return <RetiradasKit onChange={handleChange} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case "repasses":
        return <Repasses onChange={handleChange} lgt={logoutUpdt} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case "contato":
        return <Contato onChange={handleChange} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case "perfil":
        return <Perfil onChange={handleChange} lgt={logoutUpdt} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      default:
        setValue("repasses")
        return <Repasses onChange={handleChange} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
    }
  };

  const handleChange = (event, newValue) => {
    if(event === "login") {
      setCurrentUpdate(`LG___${newValue}`)
      return
    }
    if(event === "logout") {
      setLogoutUpdate(true);
      return
    }
    if(event === "logoutSuccessful") {
      setLogoutUpdate(false);
      return
    }
    setValue(newValue);
    setCurrentUpdate(`UPDT___${newValue}`)
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <CssBaseline />
      <SideMenu onChange={handleChange} update={currentUpdate} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />

      {renderContent(value)}

    </ThemeProvider>
  );
};

export default Home;
