import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';

import { Typography, TextField, InputAdornment, Button, Box, Divider, Avatar, Grid, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountCircle, AdminPanelSettings, AdminPanelSettingsOutlined, ArrowBack, Error, LogoutOutlined, VisibilityOffOutlined, VisibilityOutlined, WorkspacePremiumOutlined } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme, stringAvatar } from '../functions/utils';

import backgroundImage from "../imagens/background-3.png";
import logoImage from "../imagens/logo.png";
import Snackbar from '@mui/material/Snackbar';
import ReCAPTCHA from 'react-google-recaptcha';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useTheme } from '@emotion/react';

const steps = [
  'Informações cadastrais',
  'Informações de login',
  'Cadastro realizado',
];

function Perfil({ onChange, lgt, colorPrimary, colorSecondary, colorTertiary }, props) {

  const state = useLocation().state;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLogged, setIsLogged] = useState("");
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState("login");
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [celular, setCelular] = useState("");
  const [emailValidado, setEmailValidado] = useState(false);
  const [showSenha, setShowSenha] = useState(false);
  const [showConfirmarSenha, setShowConfirmarSenha] = useState(false);
  const [showConfirmarNovaSenha, setShowConfirmarNovaSenha] = useState(false);
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMessageSenha, setErrorMessageSenha] = useState("");
  const [errorMessageConfirmarSenha, setErrorMessageConfirmarSenha] = useState("");
  const [errorMessageConfirmarNovaSenha, setErrorMessageConfirmarNovaSenha] = useState("");
  const [errorSenha, setErrorSenha] = useState(false);
  const [errorConfirmarSenha, setErrorConfirmarSenha] = useState(false);
  const [errorConfirmarNovaSenha, setErrorConfirmarNovaSenha] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [isParceiro, setIsParceiro] = useState(false);
  const recaptchaRef = React.createRef();
  const [boxMsg, setBoxMsg] = useState("");
  const [captchaValido, setCaptchaValido] = useState(false);
  const [step, setStep] = useState("");

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaValido(true);
      setBoxMsg("");
    }
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleNome = (event) => {
    setNome(event.target.value);
  };

  const handleCelular = (event) => {
    let valor = event.target.value.replace(/\D/g, '');

    if (valor.length > 11) valor = valor.slice(0, 11);

    if (valor.length > 7) {
      valor = `(${valor.slice(0, 2)}) ${valor.slice(2, 7)}-${valor.slice(7)}`;
    } else if (valor.length > 2) {
      valor = `(${valor.slice(0, 2)}) ${valor.slice(2)}`;
    } else if (valor.length > 0) {
      valor = `(${valor}`;
    }

    setCelular(valor);
  };

  const handleCpf = (event) => {
    let cpf = event.target.value.replace(/\D/g, '');

    if (cpf.length > 11) cpf = cpf.slice(0, 11);

    if (cpf.length > 9) {
      cpf = `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9)}`;
    } else if (cpf.length > 6) {
      cpf = `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6)}`;
    } else if (cpf.length > 3) {
      cpf = `${cpf.slice(0, 3)}.${cpf.slice(3)}`;
    }

    setCpf(cpf);
    setErrorEmail(false)
    setErrorMessageEmail("")
    setEmailValidado(false)
  };

  const handleSenha = (event) => {
    setSenha(event.target.value);
    if (event.target.value.length < 6 && screen === "cadastro") {
      setErrorSenha(true)
      setErrorMessageSenha("Sua senha precisa ter no mínimo 6 digitos")
    } else {
      setErrorSenha(false)
      setErrorMessageSenha("")
    }
  };

  const handleConfirmarSenha = (event) => {
    setConfirmarSenha(event.target.value);
    if (senha !== event.target.value && !isLogged) {
      setErrorConfirmarSenha(true)
      setErrorMessageConfirmarSenha("As senhas não conferem")
    } else {
      if (event.target.value.length < 6 && isLogged) {
        setErrorConfirmarSenha(true)
        setErrorMessageConfirmarSenha("Sua senha precisa ter no mínimo 6 digitos")
      } else {
        setErrorConfirmarSenha(false)
        setErrorMessageConfirmarSenha("")
      }
    }
  };

  const handleConfirmarNovaSenha = (event) => {
    setConfirmarNovaSenha(event.target.value);
    if (confirmarSenha !== event.target.value) {
      setErrorConfirmarNovaSenha(true)
      setErrorMessageConfirmarNovaSenha("As senhas não conferem")
    } else {
      setErrorConfirmarNovaSenha(false)
      setErrorMessageConfirmarNovaSenha("")
    }
  };

  useEffect(() => {
    const logged = Cookies.get("isLogged");
    if (logged) {
      setEmail(Cookies.get("emUsr"))
      setCelular(Cookies.get("cUsr"))
      setNome(Cookies.get("nmUsr"))
      setCpf(Cookies.get("cpUsr"))
      setSenha("")
      setConfirmarSenha("")
    }
    setIsLogged(logged)
  }, []);

  useEffect(() => {
    if (lgt) {
      onChange("logoutSuccessful", "")
      handleLogout();
    }
  }, [lgt]);

  const handleLogout = () => {
    Cookies.remove('isLogged');
    Cookies.remove('emUsr');
    Cookies.remove('cpUsr');
    Cookies.remove('cUsr');
    Cookies.remove('iUsr');
    Cookies.remove('nmUsr');
    Cookies.remove('tpCt');
    setIsLogged(false)
  }

  const handleAlterarDados = () => {
    axios.post(`https://esportizese.online/api/editar-dados.php`, {
      idusuarios: Cookies.get("iUsr"),
      nome: nome,
      telefone: celular,
    })
      .then(response => {
        response = response.data
        if (!response) {
          handleSnack("Não foi possivel alterar os dados, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "falha atualizar") {
          handleSnack("Não foi possivel alterar os dados, verifique sua conexão e tente novamente.")
          return;
        }

        setSenha("")
        setConfirmarSenha("")
        setConfirmarNovaSenha("")
        handleSnack("Os seus dados foram atualizados com sucesso.")

        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor (Código 1008)"));
  }

  const handleAlterarSenha = () => {
    axios.post(`https://esportizese.online/api/editar-senha.php`, {
      idusuarios: Cookies.get("iUsr"),
      senha: senha,
      newSenha: confirmarSenha,
    })
      .then(response => {
        response = response.data
        if (!response) {
          handleSnack("Não foi possivel alterar senha, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "falha atualizar") {
          handleSnack("Não foi possivel alterar senha, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "senha curta") {
          handleSnack("A nova senha precisa ter pelo menos 6 caracteres, verifique e tente novamente.")
          return;
        }
        if (response.resposta === "senha invalida") {
          handleSnack("A senha atual digitada não está correta, verifique e tente novamente.")
          return;
        }

        setSenha("")
        setConfirmarSenha("")
        setConfirmarNovaSenha("")
        handleSnack("A sua senha foi atualizada com sucesso.")

        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor (Código 1006)"));
  }

  const handleLogin = () => {
    if (screen === "cadastro") {
      if (step === 2) {
        onChange("", "retiradas")
      }
      if (step === 0) {
        if (!(nome.length > 0)) {
          handleSnack("Preencha o nome para avançar.")
          return
        }
        if (cpf.length < 14) {
          handleSnack("Preencha um CPF válido para avançar.")
          return
        }
        if (celular.length < 15) {
          handleSnack("Preencha um celular válido para avançar.")
          return
        }
        setStep(1)
      }
      if (step === 1) {
        if (!(email.length > 0)) {
          handleSnack("Preencha o e-mail para avançar.")
          return
        }
        if (senha.length < 6) {
          handleSnack("Preencha uma senha válida para avançar.")
          return
        }
        if (senha !== confirmarSenha) {
          handleSnack("As senhas precisam estar iguais para avançar.")
          return
        }

        axios.post(`https://esportizese.online/api/cadastro.php`, {
          nome: nome.trim(),
          cpf: cpf.trim(),
          celular: celular.trim(),
          email: email.trim(),
          senha: senha
        })
          .then(response => {
            response = response.data
            if (!response) {
              handleSnack("Não foi possivel cadastrar, verifique sua conexão e tente novamente.")
              return;
            }
            if (response.resposta === "nome invalido") {
              handleSnack("O nome digitado não é válido, verifique e tente novamente.")
              return;
            }

            if (response.resposta === "nome especial") {
              handleSnack("O nome digitado possui caracteres especiais, verifique e tente novamente.")
              return;
            }

            if (response.resposta === "celular invalido") {
              handleSnack("O telefone não é válido, verifique e tente novamente.")
              return;
            }

            if (response.resposta === "cpf invalido") {
              handleSnack("O CPF não é válido, verifique e tente novamente.")
              return;
            }

            if (response.resposta === "email invalido") {
              handleSnack("O e-mail não é válido, verifique e tente novamente.")
              return;
            }

            if (response.resposta === "email cadastrado") {
              handleSnack("O e-mail ou CPF digitado já possui um cadastro, verifique e tente novamente.")
              return;
            }

            if (response.resposta === "senha curta") {
              handleSnack("A sua senha precisa ter pelo menos 6 caracteres, verifique e tente novamente.")
              return;
            }

            if (response.resposta === "falha") {
              handleSnack("Não foi possivel conectar ao servidor (Código 1003)")
              return;
            }

            for (let user of response) {
              Cookies.set('isLogged', true, { expires: 7 });
              Cookies.set('emUsr', user.email, { expires: 7 });
              Cookies.set('cpUsr', user.cpf, { expires: 7 });
              Cookies.set('cUsr', user.telefone, { expires: 7 });
              Cookies.set('iUsr', user.idusuarios, { expires: 7 });
              Cookies.set('nmUsr', user.nome, { expires: 7 });
              Cookies.set('tpCt', user.tipo_conta, { expires: 7 });
              setEmail(user.email)
              setCelular(user.telefone)
              setNome(user.nome)
              setSenha("")
              setConfirmarSenha("")
            }

            setConfirmarSenha("")
            setStep(2)
            handleCloseLoading();
          })
          .catch(error => handleSnack("Não foi possivel conectar ao servidor (Código 1004)"));
      }
      return
    }
    if (screen === "login") {
      handleOpenLoading();
      if (emailValidado) {
        if (!captchaValido) {
          handleSnack("Você precisa confirmar que não é um robô.")
          handleCloseLoading();
          return
        }
        const post_url = "https://esportizese.online/api/login.php";
        const json = JSON.stringify({
          cpf: cpf,
          senha: senha,
          isParceiro: isParceiro ? "PN" : "US"
        });

        axios.post(post_url, json)
          .then(resp => {
            const response = resp.data
            if (response === null || response === undefined) {
              handleSnack("Senha inválida")
              handleCloseLoading();
            }
            else {
              if (response.resposta === "email invalido") {
                handleSnack("E-mail inválido")
                handleCloseLoading();
              } else {
                for (let user of response) {
                  Cookies.set('isLogged', true, { expires: 7 });
                  Cookies.set('emUsr', user.email, { expires: 7 });
                  Cookies.set('cpUsr', user.cpf, { expires: 7 });
                  Cookies.set('cUsr', user.telefone, { expires: 7 });
                  Cookies.set('iUsr', user.idusuarios, { expires: 7 });
                  Cookies.set('nmUsr', user.nome, { expires: 7 });
                  Cookies.set('tpCt', user.tipo_conta, { expires: 7 });
                  setEmail(user.email)
                  setCelular(user.telefone)
                  setNome(user.nome)
                  setSenha("")
                  setConfirmarSenha("")
                  setIsLogged(true)
                  onChange("login", "")
                }

                handleCloseLoading();
              }
            }

          })
          .catch(error => {
            handleSnack("Não foi possivel conectar ao servidor (Código 1005)")
            handleCloseLoading();
          });
      } else {
        const post_url = "https://esportizese.online/api/valida-cpf.php";
        const json = JSON.stringify({
          cpf: cpf,
          isParceiro: isParceiro ? "PN" : "US"
        });

        axios.post(post_url, json)
          .then(resp => {
            const response = resp.data
            if (response === null || response === undefined) {
              handleErrorEmail("CPF não encontrado no sistema. Por favor, verifique e tente novamente.")
              handleCloseLoading();
            }
            else {
              if (response.resposta === "cpf invalido") {
                handleErrorEmail("O CPF digitado é inválido. Por favor, verifique e tente novamente.")
                handleCloseLoading();
              } else if (response.resposta === "cpf nao cadastrado") {
                handleErrorEmail("CPF não encontrado no sistema. Por favor, verifique e tente novamente.")
                handleCloseLoading();
              } else {
                setEmailValidado(true);
                handleCloseLoading();
              }
            }

          })
          .catch(error => {
            handleErrorEmail("Não foi possivel conectar ao servidor (Código 1001)")
            handleCloseLoading();
          });
      }
    } else {
      handleReenviarSenha();
      return
    }
  }

  const handleErrorEmail = (message) => {
    setErrorEmail(true);
    setErrorMessageEmail(message)
  }

  const handleErrorSenha = (message) => {
    setErrorSenha(true);
    setErrorMessageSenha(message)
  }

  const handleShowSenha = () => {
    setShowSenha(!showSenha)
  }

  const handleShowConfirmarSenha = () => {
    setShowConfirmarSenha(!showConfirmarSenha)
  }

  const handleShowConfirmarNovaSenha = () => {
    setShowConfirmarNovaSenha(!showConfirmarNovaSenha)
  }

  const handleParceiro = () => {
    setEmailValidado(false)
    setErrorEmail(false)
    setErrorMessageEmail("")
    setErrorSenha(false)
    setErrorMessageSenha("")
    setIsParceiro(true)
  }

  const handleAtleta = () => {
    setEmailValidado(false)
    setErrorEmail(false)
    setErrorMessageEmail("")
    setErrorSenha(false)
    setErrorMessageSenha("")
    setIsParceiro(false)
  }

  const handleBackLogin = () => {
    resetCampos()
    if (screen === "cadastro" && step === 1) {
      setStep(0);
      return
    }
    setScreen("login")
  }

  const handleEsqueciSenha = () => {
    setEmailValidado(false)
    resetCampos()
    setScreen("esqueci")
  }

  const handleCadastro = () => {
    setStep(0)
    setEmailValidado(false)
    resetCampos()
    setScreen("cadastro")
  }

  const resetCampos = () => {
    setErrorSenha(false);
    setErrorMessageSenha("")
    setErrorEmail(false);
    setErrorMessageEmail("")
  }

  const handleReenviarSenha = () => {
    setEmailValidado(false)
    handleOpenLoading();
    const post_url = "https://esportizese.online/api/reenviar-senha.php";
    const json = JSON.stringify({
      email: email,
      isParceiro: isParceiro ? "PN" : "US"
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleErrorEmail("Não foi possivel reenviar sua senha. Por favor, verifique e tente novamente.")
          handleCloseLoading();
        }
        else {
          if (response.resposta === "email invalido") {
            handleErrorEmail("O CPF digitado é inválido. Por favor, verifique e tente novamente.")
            handleCloseLoading();
          } else if (response.resposta === "email nao cadastrado") {
            handleErrorEmail("CPF não encontrado no sistema. Por favor, verifique e tente novamente.")
            handleCloseLoading();
            return;
          } else if (response.resposta === "falha") {
            handleErrorEmail("Não foi possivel reenviar sua senha. Por favor, verifique e tente novamente.")
            handleCloseLoading();
            return;
          } else {
            handleSnack("Um e-mail de redefinição foi enviado para você.")
            handleCloseLoading();
          }
        }
      })
      .catch(error => {
        handleErrorEmail("Não foi possivel conectar ao servidor (Código 1002)")
        handleCloseLoading();
      });
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: 'fixed',
          top: 85,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10,
        }}>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        {!isLogged ?
          <Box
            sx={{
              backgroundColor: 'white',
              padding: '30px',
              borderRadius: '8px',
              border: "solid 1px #333",
              // boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
              textAlign: 'center',
              maxWidth: 600,
              width: "100%",
              marginTop: -20
            }}
          >

            <Typography style={{ textAlign: "right", fontSize: "24px", fontWeight: "600" }}>
              {screen === "login" && isParceiro && <>Área do Parceiro<br /><Typography style={{ fontWeight: "normal", fontSize: 14, lineHeight: 1.7 }}>Quer retirar kits como um associado da esportize-se? <br />Entre em contato para mais detalhes.</Typography></>}
              {screen === "login" && !isParceiro && <>Bem vindo Atleta<br /><Typography style={{ fontWeight: "normal", fontSize: 14, lineHeight: 1.7 }}>Retire seus kits, repasse ou compre corridas esgotadas. <br />Crie sua conta e aproveite todos os benefícios da esportize-se</Typography></>}
              {screen === "esqueci" && <>Esqueceu a senha?<br /><Typography style={{ fontWeight: "normal", fontSize: 14, lineHeight: 1.7 }}>Não se preocupe! Digite seu e-mail abaixo e enviaremos um link para redefinir sua senha.</Typography></>}
              {screen === "cadastro" && <>Cadastre-se<br /><Typography style={{ fontWeight: "normal", fontSize: 13, lineHeight: 1.7 }}>Não se preocupe! Cadastre-se rapidamente e aproveite todos os beneficios que só a esportize-se tem para você.</Typography></>}
            </Typography>

            <div style={{ height: 20 }} />

            {screen === "cadastro" &&
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={step} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div style={{ height: 16 }} />
                <Divider />
                <div style={{ height: 16 }} />
              </Box>
            }

            {screen === "cadastro" && step === 0 &&
              <>
                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  Nome
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={nome}
                  onChange={handleNome}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui seu nome"
                  type="text"
                />
                <div style={{ height: 10 }} />
              </>
            }

            {(screen !== "cadastro" || (screen === "cadastro" && step === 0)) &&
              <>
                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  CPF
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={cpf}
                  onChange={handleCpf}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui seu CPF"
                  type="text"
                  error={errorEmail}
                  helperText={errorMessageEmail}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errorEmail &&
                          <InputAdornment style={{ color: "#d32f2f" }}>
                            <Error />
                          </InputAdornment>
                        }
                      </>
                    ),
                  }}
                />
              </>
            }
            {screen === "cadastro" && step === 0 &&
              <>
                <div style={{ height: 10 }} />
                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  Celular
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={celular}
                  onChange={handleCelular}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui seu celular"
                  type="text"
                />
                <div style={{ height: 20 }} />
              </>
            }
            {screen === "cadastro" && step === 1 &&
              <>
                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  E-mail
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={email}
                  onChange={handleEmail}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui seu e-mail"
                  type="text"
                  error={errorEmail}
                  helperText={errorMessageEmail}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errorEmail &&
                          <InputAdornment style={{ color: "#d32f2f" }}>
                            <Error />
                          </InputAdornment>
                        }
                      </>
                    ),
                  }}
                />
              </>
            }
            {(emailValidado || (screen === "cadastro" && step === 1)) &&
              <>
                <div style={{ height: 10 }} />
                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  Senha
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={senha}
                  onChange={handleSenha}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui sua senha"
                  type={showSenha ? "text" : "password"}
                  error={errorSenha}
                  helperText={errorMessageSenha}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errorSenha ?
                          <InputAdornment style={{ color: "#d32f2f" }}>
                            <Error />
                          </InputAdornment>
                          :
                          <InputAdornment style={{ cursor: "pointer" }}>
                            {showSenha ?
                              <VisibilityOutlined onClick={handleShowSenha} />
                              :
                              <VisibilityOffOutlined onClick={handleShowSenha} />
                            }
                          </InputAdornment>
                        }
                      </>
                    ),
                  }}
                />
                {screen === "cadastro" &&
                  <>
                    <div style={{ height: 10 }} />
                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Confirmar senha
                    </Typography>
                    <div style={{ height: 4 }} />
                    <TextField
                      fullWidth
                      hiddenLabel
                      value={confirmarSenha}
                      onChange={handleConfirmarSenha}
                      variant="outlined"
                      size="large"
                      placeholder="Digite aqui sua senha"
                      type={showConfirmarSenha ? "text" : "password"}
                      error={errorConfirmarSenha}
                      helperText={errorMessageConfirmarSenha}
                      InputProps={{
                        endAdornment: (
                          <>
                            {errorConfirmarSenha ?
                              <InputAdornment style={{ color: "#d32f2f" }}>
                                <Error />
                              </InputAdornment>
                              :
                              <InputAdornment style={{ cursor: "pointer" }}>
                                {showConfirmarSenha ?
                                  <VisibilityOutlined onClick={handleShowConfirmarSenha} />
                                  :
                                  <VisibilityOffOutlined onClick={handleShowConfirmarSenha} />
                                }
                              </InputAdornment>
                            }
                          </>
                        ),
                      }}
                    />
                    <div style={{ height: 20 }} />
                  </>}
              </>
            }
            {screen === "login" &&
              <Button variant="text" size="large" aria-label="settings" style={{ float: "right", textTransform: "none", fontWeight: "bold", width: "180px" }} onClick={handleEsqueciSenha}>
                Esqueci minha senha
              </Button>
            }
            {emailValidado && screen === "login" && <br />}
            {screen === "login" && <br />}
            <div style={{ height: 0 }}></div>
            {emailValidado && screen === "login" &&
              <>
                <ReCAPTCHA style={{ width: '100%' }}
                  ref={recaptchaRef}
                  sitekey="6LfPU2IqAAAAALWtDVY12aKBEoEuWIGNL321intB"
                  onChange={handleCaptchaChange}
                />
                <div style={{ height: 20 }} />
              </>
            }
            {screen === "esqueci" &&
              <div style={{ height: 20, }} />
            }


            {screen === "cadastro" && step === 2 &&
              <>
                <WorkspacePremiumOutlined style={{ fontSize: 120, color: "orange" }} />
                <Typography style={{ fontSize: 13 }}>
                  <b style={{ fontSize: 18 }}>Parabéns</b><br />Você concluiu o cadastro com sucesso.<br />Clique no botão abaixo para aproveitar todos os beneficios do esportize-se.
                  <div style={{ height: 26 }} />
                </Typography>
              </>
            }
            <Button variant="contained" size="large" fullWidth aria-label="settings" style={{ textTransform: "none", fontWeight: "bold", color: "white" }} onClick={handleLogin}>
              {screen === "login" && <>Entrar</>}
              {screen === "esqueci" && <>Enviar e-mail</>}
              {screen === "cadastro" && step < 2 && <>Avançar</>}
              {screen === "cadastro" && step === 2 && <>Concluir</>}
            </Button>

            <div style={{ height: 10 }} />

            {screen !== "login" && step < 2 &&
              <Button size="large" startIcon={<ArrowBack />} fullWidth aria-label="settings" style={{ textTransform: "none", fontWeight: "bold" }} onClick={handleBackLogin}>
                {screen === "esqueci" && "Voltar para o login"}
                {screen === "cadastro" && step === 0 && "Voltar para o login"}
                {screen === "cadastro" && step === 1 && "Voltar para informações cadastrais"}
              </Button>
            }

            {screen === "login" &&
              <>
                <div style={{ height: 10 }} />
                <Typography style={{ textAlign: "center" }}>
                  {!isParceiro ?
                    <>
                      É um parceiro? <b style={{ cursor: "pointer", color: "#ff9900" }} onClick={handleParceiro}>Clique aqui</b>
                    </>
                    :
                    <>
                      Deseja entrar como atleta? <b style={{ cursor: "pointer", color: "#ff9900" }} onClick={handleAtleta}>Clique aqui</b>
                    </>
                  }
                </Typography>
                {!isParceiro &&
                  <>
                    <div style={{ height: 10 }} />
                    <Divider />
                    <div style={{ height: 25 }} />
                    <Button variant="contained" color="secondary" size="large" fullWidth aria-label="settings" style={{ textTransform: "none", fontWeight: "bold", color: "white" }} onClick={handleCadastro}>
                      Não tenho cadastro
                    </Button>
                  </>
                }
              </>
            }
            <div style={{ height: 10 }} />
          </Box>
          :
          <Box
            sx={{
              backgroundColor: 'white',
              padding: '30px',
              borderRadius: '8px',
              border: "solid 1px #333",
              // boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
              textAlign: 'center',
              maxWidth: 1300,
              width: "100%",
              marginTop: isMobile ? 0 : -14,
              maxHeight: isMobile ? "100%" : 800,
              overflow: "auto"
            }}
          >


            <div style={{ textAlign: "center", display: "flex" }}>
              <Typography style={{ margin: "auto" }}>
                <Avatar {...stringAvatar("Joelson Lucas")} style={{ width: 100, height: 100, fontSize: 36, fontWeight: "400" }} />
              </Typography>
            </div>
            <div style={{ height: 32 }} />
            <Typography style={{ fontSize: 22, fontWeight: "bold", textAlign: "left" }}>
              Dados cadastrais <AccountCircle style={{ fontSize: 34, color: "#ff9900", marginBottom: -10 }} />
            </Typography>

            <div style={{ height: 32 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0 }}>
                <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
                  Nome
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={nome}
                  onChange={handleNome}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui seu nome"
                  type="text"
                />
                <div style={{ height: 10 }} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0, opacity: .7 }}>
                <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
                  CPF
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={cpf}
                  onChange={handleCpf}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui seu CPF"
                  type="text"
                  disabled
                />
                <div style={{ height: 10 }} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0 }}>
                <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
                  Celular
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={celular}
                  onChange={handleCelular}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui seu celular"
                  type="text"
                />
                <div style={{ height: 10 }} />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0, opacity: .7 }}>
                <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
                  E-mail
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={email}
                  onChange={handleEmail}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui seu e-mail"
                  type="text"
                  disabled
                  sx={{
                    cursor: "not-allowed",
                    "& .MuiInputBase-root": {
                      cursor: "not-allowed",
                    }
                  }}
                />
                <div style={{ height: 20 }} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0 }} />
              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0 }} />


              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0 }}>
                <Button variant="contained" size="large" fullWidth aria-label="settings" style={{ textTransform: "none", fontWeight: "bold", color: "white" }} onClick={handleAlterarDados}>
                  Alterar dados cadastrais
                </Button>
                <div style={{ height: 10 }} />
              </Grid>
            </Grid>

            <div style={{ height: 14 }} />
            <Divider />
            <div style={{ height: 14 }} />
            <Typography style={{ fontSize: 22, fontWeight: "bold", textAlign: "left" }}>
              Senha e segurança <AdminPanelSettings style={{ fontSize: 34, color: "#ff9900", marginBottom: -10 }} />
            </Typography>
            <div style={{ height: 32 }} />

            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0 }}>
                <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
                  Senha antiga
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={senha}
                  onChange={handleSenha}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui sua senha antiga"
                  type={showSenha ? "text" : "password"}
                  error={errorSenha}
                  helperText={errorMessageSenha}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errorSenha ?
                          <InputAdornment style={{ color: "#d32f2f" }}>
                            <Error />
                          </InputAdornment>
                          :
                          <InputAdornment style={{ cursor: "pointer" }}>
                            {showSenha ?
                              <VisibilityOutlined onClick={handleShowSenha} />
                              :
                              <VisibilityOffOutlined onClick={handleShowSenha} />
                            }
                          </InputAdornment>
                        }
                      </>
                    ),
                  }}
                />
                <div style={{ height: 10 }} />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0 }}>
                <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
                  Nova senha
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={confirmarSenha}
                  onChange={handleConfirmarSenha}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui sua senha"
                  type={showConfirmarSenha ? "text" : "password"}
                  error={errorConfirmarSenha}
                  helperText={errorMessageConfirmarSenha}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errorConfirmarSenha ?
                          <InputAdornment style={{ color: "#d32f2f" }}>
                            <Error />
                          </InputAdornment>
                          :
                          <InputAdornment style={{ cursor: "pointer" }}>
                            {showConfirmarSenha ?
                              <VisibilityOutlined onClick={handleShowConfirmarSenha} />
                              :
                              <VisibilityOffOutlined onClick={handleShowConfirmarSenha} />
                            }
                          </InputAdornment>
                        }
                      </>
                    ),
                  }}
                />
                <div style={{ height: 10 }} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0 }}>
                <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
                  Confirmar nova senha
                </Typography>
                <div style={{ height: 4 }} />
                <TextField
                  fullWidth
                  hiddenLabel
                  value={confirmarNovaSenha}
                  onChange={handleConfirmarNovaSenha}
                  variant="outlined"
                  size="large"
                  placeholder="Digite aqui sua senha"
                  type={showConfirmarNovaSenha ? "text" : "password"}
                  error={errorConfirmarNovaSenha}
                  helperText={errorMessageConfirmarNovaSenha}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errorConfirmarNovaSenha ?
                          <InputAdornment style={{ color: "#d32f2f" }}>
                            <Error />
                          </InputAdornment>
                          :
                          <InputAdornment style={{ cursor: "pointer" }}>
                            {showConfirmarNovaSenha ?
                              <VisibilityOutlined onClick={handleShowConfirmarNovaSenha} />
                              :
                              <VisibilityOffOutlined onClick={handleShowConfirmarNovaSenha} />
                            }
                          </InputAdornment>
                        }
                      </>
                    ),
                  }}
                />
                <div style={{ height: 20 }} />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 0 }}>
                <Button variant="contained" size="large" fullWidth aria-label="settings" style={{ textTransform: "none", fontWeight: "bold", color: "white" }} onClick={handleAlterarSenha}>
                  Alterar senha
                </Button>
              </Grid>
            </Grid>
            <div style={{ height: 20 }} />

          </Box>
        }
      </Box>

    </ThemeProvider>
  );
}

export default Perfil;