import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { Button, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, ListItemButton, ListItemText, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { Check, Close, DragIndicator, PhoneOutlined, WhatsApp } from '@mui/icons-material';

import ReCAPTCHA from 'react-google-recaptcha';

function Contato({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [tipoSuporte, setTipoSuporte] = useState(0);
  const [loading, setLoading] = useState(false);
  const [boxMsg, setBoxMsg] = useState("");
  const [mensagemBox, setMensagemBox] = useState(false);
  const [nome, setNome] = useState("");
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [limiteCaracteres, setLimiteCaracteres] = useState("0/140");
  const [value, setValue] = React.useState(0);
  const [captchaValido, setCaptchaValido] = useState(false);
  const recaptchaRef = React.createRef();

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaValido(true);
      setBoxMsg("");
    }
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleNome = (event) => {
    setNome(event.target.value);
  };

  const handleNomeEmpresa = (event) => {
    setNomeEmpresa(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleTelefone = (event) => {
    setTelefone(event.target.value);
  };

  const handleMensagem = (event) => {
    const mensagem = event.target.value
    setLimiteCaracteres(`${mensagem.length}/140`)
    setMensagem(mensagem);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleContato = () => {
    onChange("", "contato")
  }

  const handleMsg = (tipo, mensagem) => {
    setBoxMsg(tipo)
    setMensagemBox(mensagem)
  }
  const handleMsgEmail = () => {
    handleOpenLoading()
    if (!nome) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha seu nome.`)
      return
    }
    if (!email) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha seu e-mail.`)
      return
    }
    if (!nomeEmpresa) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha o nome da sua empresa.`)
      return
    }
    if (!telefone) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha seu telefone.`)
      return
    }
    if (!mensagem) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha a mensagem.`)
      return
    }

    if (!captchaValido) {
      handleCloseLoading()
      handleMsg("error", `Por favor, confirme que você não é um robô.`)
      return
    }

    recaptchaRef.current.reset();

    const post_url = `https://elysolucoestecnologicas.com.br/api/request-contact.php?nome=${nome}&nomeempresa=${nomeEmpresa}&email=${email}&telefone=${telefone}&mensagem=${mensagem}`;

    axios.post(post_url)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "success") {
            handleCloseLoading()
            handleMsg("success", `Sua solicitação foi enviada com sucesso.`)
            setMensagem("")
            setNome("")
            setEmail("")
            setTelefone("")
            setNomeEmpresa("")
            return
          }
        }
        handleCloseLoading()
        handleMsg("error", `Não foi possivel enviar a sua solicitação. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => {
        console.log(error)
        handleCloseLoading()
        handleMsg("error", `Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  }

  const handleWhatsapp = (text) => {
    window.open(`https://wa.me/55${text}?text=Olá,%20preciso%20de%20ajuda%20com%20o%20mely!`, '_blank');
  };

  useEffect(() => {
    const email = Cookies.get('emEst');
    setEmail(email)
  }, []);

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      
    </ThemeProvider>
  );
}

export default Contato;