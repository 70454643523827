import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme, formatarDataHora, formatarDataT, getFirstName, maskPhone, maskReais, stringAvatar } from '../functions/utils';
import { Alert, Avatar, Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, Snackbar, SwipeableDrawer, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
// import Grid from '@mui/material/Grid2';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AccessTime, AttachMoneyOutlined, CalendarMonthOutlined, Check, CheckCircle, Close, ConnectWithoutContactOutlined, DevicesOutlined, FacebookOutlined, HubOutlined, Instagram, InterestsOutlined, KeyboardArrowDown, LanOutlined, LinkedIn, LocationOnOutlined, ReduceCapacityOutlined, Route, Search, SentimentVerySatisfiedOutlined, StraightenOutlined, SupervisedUserCircleOutlined, Visibility, WhatsApp, WidgetsOutlined } from '@mui/icons-material';
import bg from '../imagens/background-2.png';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from "dayjs";

import ReCAPTCHA from 'react-google-recaptcha';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Repasses({ onChange, lgt, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const container = windows !== undefined ? () => windows().document.body : undefined;

  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [pesquisa, setPesquisa] = useState("");
  const [local, setLocal] = useState("Todos");
  const [data, setData] = useState("Todas");
  const [modalidade, setModalidade] = useState("Todas");
  const [repasses, setRepasses] = React.useState("");
  const [repassesOriginal, setRepassesOriginal] = React.useState("");
  const [abaRepasse, setAbaRepasse] = useState(0);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [idUsuario, setIdUsuario] = useState();
  const [filter, setFilter] = useState("");
  const [openSwipe, setOpenSwipe] = useState(false);
  const [repasseSelecionado, setRepasseSelecionado] = useState("");
  const [nome, setNome] = useState("");
  const [celular, setCelular] = useState("");
  const [valor, setValor] = useState("");
  const [nomeEvento, setNomeEvento] = useState("");
  const [dataHora, setDataHora] = useState(null);
  const [tamanho, setTamanho] = useState("");
  const [distancia, setDistancia] = useState("");
  const [status, setStatus] = useState("");
  const [localEvento, setLocalEvento] = useState("");
  const [isFirst, setIsFirst] = useState("");
  const fileInputRef = useRef(null);

  const recaptchaRef = React.createRef();

  const [open, setOpen] = React.useState(false);

  const handleOpenFilter = (filter) => {
    setRepasseSelecionado("")
    setFilter(filter)
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setRepasseSelecionado("")
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenSwipe(newOpen);
  };

  const handleChange = (event, newValue) => {
    setAbaRepasse(newValue);
  };

  const handleLocal = (event) => {
    setOpen(false)
    setLocal(event.target.value);
  };

  const handleData = (event) => {
    setOpen(false)
    setData(event.target.value);
  };

  const handleModalidade = (event) => {
    setOpen(false)
    setModalidade(event.target.value);
  };

  const handleNome = (event) => {
    setNome(event.target.value);
  };

  const handleCelular = (event) => {
    setCelular(maskPhone(event.target.value));
  };

  const handleTamanho = (event) => {
    setTamanho(event.target.value);
  };

  const handleNomeEvento = (event) => {
    setNomeEvento(event.target.value);
  };

  const handleDistancia = (event) => {
    setDistancia(event.target.value);
  };

  const handleValor = (event) => {
    setValor(maskReais(event.target.value));
  };

  const handleDataHora = (event) => {
    setDataHora(event.target.value);
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleLocalEvento = (event) => {
    setLocalEvento(event.target.value);
  };

  const handlePesquisa = (event) => {
    setPesquisa(event.target.value);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleAceitarTermo = (event, reason) => {
    setOpen(false);
    setIsFirst(true);
    Cookies.set('isFirst', true, { expires: 30 });
  };

  const handleUploadFile = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      const validFileTypes = ['image/png', 'image/jpeg'];

      if (!validFileTypes.includes(fileType)) {
        handleSnack("Somente arquivos PNG ou JPG são permitidos.")
        setSelectedImage(null);
        setSelectedImageFile(null)
        return;
      }

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        handleSnack("O arquivo deve ter no máximo 5MB.")
        setSelectedImage(null);
        setSelectedImageFile(null)
        return;
      }

      const reader = new FileReader();
      setSelectedImageFile(file)
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const isFirst = Cookies.get("isFirst");
    if (!isFirst) {
      setOpen(true)
    }
    setIsFirst(isFirst)
    const logged = Cookies.get("isLogged");
    setIsLogged(logged)
    if (logged) {
      const idUsuario = Cookies.get("iUsr");
      setIdUsuario(idUsuario)
    }
    getRepasses("RP")
  }, []);

  useEffect(() => {
    const logged = Cookies.get("isLogged");
    setIsLogged(logged)
    if (logged) {
      const idUsuario = Cookies.get("iUsr");
      setIdUsuario(idUsuario)
    }
    getRepasses("RP")
  }, [lgt]);

  const getRepasses = (tipo) => {
    axios.post(`https://esportizese.online/api/produtos.php`, {
      tipo: tipo,
    })
      .then(response => {
        response = response.data
        setRepasses(response)
        setRepassesOriginal(response)
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor (Código 1008)"));
  }

  const handleLogin = () => {
    onChange("", "perfil")
  }

  const handleOpenWhatsapp = (numero, corrida) => {
    const soNumero = numero.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "")
    window.open(`https://wa.me/55${soNumero}?text=Olá,tenho%20interesse%20em%20comprar%20o%20kit%20do%20evento%20*"${corrida}"*`, '_blank');
  }

  const handleFiltrar = () => {
    setRepasses(repassesOriginal.filter(item =>
      (item.LC_IT.toLowerCase().includes(local.toLowerCase()) || local == "Todos") &&
      (formatarDataT(item.DT_IT * 1000).includes(formatarDataT(data * 1000)) || data == "Todas") &&
      (item.MD_IT.includes(modalidade) || modalidade == "Todas") &&
      item.NM_IT.toLowerCase().includes(pesquisa.toLowerCase())
    ))
  }

  const handleAnunciar = () => {
    limparCampos()
    setStatus("A")
    setModalidade("Corrida de rua")
    setOpenSwipe(true)
    setRepasseSelecionado("")
  }

  const limparCampos = () => {
    setSelectedImage(null);
    setSelectedImageFile(null)
    setNome(Cookies.get("nmUsr"))
    setCelular(Cookies.get("cUsr"))
    setTamanho("")
    setNomeEvento("")
    setDistancia("")
    setValor("")
    setLocalEvento("")
    setDataHora(null)
    setModalidade("")
    setStatus("")
  }

  const handleRepasse = (repasse) => {
    if (!isLogged) {
      onChange("", "perfil")
      return
    }
    if (abaRepasse === 1) {
      const data = dayjs(repasse.DT_IT * 1000);

      setSelectedImage(null);
      setSelectedImageFile(null)
      setOpenSwipe(true)
      setRepasseSelecionado(repasse)
      setNome(repasse.NM_AN)
      setCelular(repasse.TF_AN)
      setTamanho(repasse.TM_IT)
      setNomeEvento(repasse.NM_IT)
      setDistancia(repasse.DSC_IT)
      setValor(repasse.VL_IT)
      setLocalEvento(repasse.LC_IT)
      setDataHora(data)
      setModalidade(repasse.MD_IT)
      setStatus(repasse.ST_IT)
      return
    }
    setRepasseSelecionado(repasse)
    setOpen(true)
    limparCampos()
  }

  const handleSalvar = async () => {
    if (nome < 2) {
      handleSnack("Preencha um nome válido para avançar.")
      return
    }
    if (celular.length < 15) {
      handleSnack("Preencha um contato válido para avançar.")
      return
    }
    if (!tamanho) {
      handleSnack("Preencha um tamanho de kit válido para avançar.")
      return
    }
    if (nomeEvento < 2) {
      handleSnack("Preencha um nome de evento válido para avançar.")
      return
    }
    if (valor < 3) {
      handleSnack("Preencha um valor de evento válido para avançar.")
      return
    }
    if (localEvento < 3) {
      handleSnack("Preencha um local de evento válido para avançar.")
      return
    }
    if (dataHora < 16) {
      handleSnack("Preencha uma data e hora de evento válida para avançar.")
      return
    }
    if (!modalidade) {
      handleSnack("Preencha um modalidade de evento válida para avançar.")
      return
    }

    const endpoint = repasseSelecionado ? "editar-repasse" : "cadastro-repasse"
    handleOpenLoading();

    const post_url = `https://esportizese.online/api/${endpoint}.php`;
    const formData = new FormData();
    selectedImageFile ? formData.append('foto', selectedImageFile) : console.log("");
    formData.append('iditem', repasseSelecionado ? repasseSelecionado.ID_IT_PDT : idUsuario);
    formData.append('nome', nome.trim())
    formData.append('celular', celular.trim())
    formData.append('tamanho', tamanho)
    formData.append('nomeEvento', nomeEvento.trim())
    formData.append('descricao', distancia.trim())
    formData.append('valor', valor.trim())
    formData.append('localEvento', localEvento.trim())
    formData.append('dataHora', dataHora.unix())
    formData.append('modalidade', modalidade)
    formData.append('status', status)

    try {
      const response = await axios.post(post_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data) {
        if (response.data.upload === "tamanho") {
          handleCloseLoading()
          handleSnack("A imagem selecionada é maior que 5MB.")
          return
        }
        if (response.data.upload === "formato") {
          handleCloseLoading()
          handleSnack("O formato da imagem selecionada não é suportado.")
          return
        }
        if (response.data.upload === "falha") {
          handleCloseLoading()
          handleSnack("Ocorreu uma falha ao atualizar a imagem selecionada.")
          return
        }

        if (!response.data.resposta) {
          handleSnack("Não foi possivel conectar ao servidor (Código 2001)")
          return;
        }
        if (response.resposta === "falha atualizar") {
          handleSnack("Não foi possivel conectar ao servidor (Código 2000)")
          return;
        }

        getRepasses("RP")
        setOpenSwipe(false)
        handleCloseLoading()
        handleSnack(`Seu repasse foi ${repasseSelecionado ? "atualizado" : "cadastrado"} com sucesso.`)
        return
      }
      handleCloseLoading()
      handleSnack("Não foi possivel conectar ao servidor. Por favor verifique sua conexão e tente novamente. (Código 2003)")
    } catch (error) {
      handleCloseLoading()
      handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente. (Código 2002)")
    }

    // axios.post(`https://esportizese.online/api/${endpoint}.php`, {
    //   iditem: repasseSelecionado ? repasseSelecionado.ID_IT_PDT : idUsuario,
    //   nome: nome.trim(),
    //   celular: celular.trim(),
    //   tamanho: tamanho,
    //   nomeEvento: nomeEvento.trim(),
    //   descricao: distancia.trim(),
    //   valor: valor.trim(),
    //   localEvento: localEvento,
    //   dataHora: dataHora.unix(),
    //   modalidade: modalidade,
    //   status: status,
    // })
    //   .then(response => {
    //     response = response.data
    //     if (!response) {
    //       handleSnack("Não foi possivel conectar ao servidor (Código 2001)")
    //       return;
    //     }
    //     if (response.resposta === "falha atualizar") {
    //       handleSnack("Não foi possivel conectar ao servidor (Código 2000)")
    //       return;
    //     }

    //     getRepasses("RP")
    //     setOpenSwipe(false)
    //     handleSnack(`Seu repasse foi ${repasseSelecionado ? "atualizado" : "cadastrado"} com sucesso.`)
    //     handleCloseLoading();
    //   })
    //   .catch(error => handleSnack("Não foi possivel conectar ao servidor (Código 2001)"));


  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msgSnack}
      />

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/png, image/jpeg"
        onChange={handleFileChange}
      />

      <LoadingComponent open={loading} />

      <div style={{ height: 80 }} />
      <div style={{ width: "100%", height: isMobile ? 150 : 240, backgroundImage: `url(${bg})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography style={{ textAlign: "center", fontSize: isMobile ? 50 : 100, fontWeight: "bold", color: "#FFF", fontStyle: "italic", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7), -2px -2px 4px rgba(0, 0, 0, 0.7), 2px -2px 4px rgba(0, 0, 0, 0.7), -2px 2px 4px rgba(0, 0, 0, 0.7)" }}>
          Repasses
        </Typography>
      </div>


      <Dialog
        open={open}
        onClose={handleClose}
      >
        {!isFirst ?
          <>
            <DialogTitle>
              <b style={{ fontSize: 22, color: "#777" }}>Política de Responsabilidade sobre Anúncios</b>
            </DialogTitle>

            <DialogContent style={{ fontSize: 17, color: "#777" }}>
              Bem-vindo ao nosso site de classificados gratuitos. Nosso objetivo é oferecer uma plataforma onde usuários podem anunciar produtos e serviços de forma livre. No entanto, gostaríamos de esclarecer alguns pontos importantes sobre a responsabilidade pelos anúncios publicados.

              Responsabilidade pelos Anúncios Todos os anúncios publicados neste site são de responsabilidade única e exclusiva do anunciante. Isso inclui a veracidade, exatidão e integridade das informações apresentadas em cada anúncio.

              Isenção de Responsabilidade do Site Este site atua apenas como um intermediário, disponibilizando um espaço para que usuários possam anunciar. O proprietário do site não verifica, endossa ou garante os produtos e serviços anunciados. Qualquer negociação ou transação realizada entre os usuários é de inteira responsabilidade dos envolvidos, e o site não será responsabilizado por perdas, danos ou fraudes decorrentes dessas transações.

              Cuidado ao Realizar Transações Recomendamos que todos os usuários tomem precauções ao entrar em contato com anunciantes e ao realizar transações. Verifique todas as informações cuidadosamente antes de prosseguir com qualquer negociação. Esteja atento a ofertas que pareçam ser enganosas ou fraudulentas.

              Denúncia de Anúncios Suspeitos Caso identifique algum anúncio suspeito ou que viole as diretrizes do site, pedimos que nos informe para que possamos tomar as devidas providências. A segurança dos nossos usuários é prioridade, e nossa equipe está comprometida em manter a plataforma segura e confiável.

              Ao utilizar o site, você concorda com os termos desta política e assume a responsabilidade por qualquer transação realizada. O uso da plataforma implica em sua aceitação destes termos.
            </DialogContent>

            <DialogActions style={{ paddingRight: 24 }}>
              <Button onClick={handleAceitarTermo} style={{ textTransform: "none" }} variant="contained">Entendi</Button>
            </DialogActions>

          </>
          :
          <>
            <DialogTitle>
              {repasseSelecionado ?
                <>
                  <b style={{ fontSize: 22 }}>{repasseSelecionado.NM_IT}</b>
                </>
                :
                <>
                  {filter === "local" && "Selecione abaixo o local desejado:"}
                  {filter === "data" && "Selecione abaixo a data desejada:"}
                  {filter === "modalidade" && "Selecione abaixo a modalidade desejado:"}
                </>
              }
            </DialogTitle>
            {repasseSelecionado ?
              <DialogContent>
                <Typography style={{ fontSize: 18 }}>Para adquirir o repasse <b>"{repasseSelecionado.NM_IT}"</b>, entre em contato com {repasseSelecionado.NM_AN} através do número {repasseSelecionado.TF_AN}, ou clique no botão abaixo para entrar em contato diretamente pelo Whatsapp</Typography>
              </DialogContent>
              :
              <DialogContent>
                {filter === "local" &&
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">Local</InputLabel> */}
                    <Select
                      value={local}
                      placeholder="Local"
                      onChange={handleLocal}
                    >
                      <MenuItem key={"Todos"} value={"Todos"}>Todos os locais</MenuItem>
                      {repassesOriginal && repassesOriginal.filter((option, index, self) => index === self.findIndex((o) => o.LC_IT === option.LC_IT)).map((option) => (
                        <MenuItem key={option.LC_IT} value={option.LC_IT}>
                          {option.LC_IT}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }

                {filter === "data" &&
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">Data</InputLabel> */}
                    <Select
                      value={data}
                      placeholder="Data"
                      onChange={handleData}
                    >
                      <MenuItem key={"Todas"} value={"Todas"}>Todas as datas</MenuItem>
                      {repassesOriginal && repassesOriginal.filter((option, index, self) => index === self.findIndex((o) => o.DT_IT === option.DT_IT)).map((option) => (
                        <MenuItem key={option.DT_IT} value={option.DT_IT}>
                          {formatarDataT(option.DT_IT * 1000)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }

                {filter === "modalidade" &&
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">Modalidade</InputLabel> */}
                    <Select
                      value={modalidade}
                      placeholder="Modalidade"
                      onChange={handleModalidade}
                    >
                      <MenuItem key={"Todas"} value={"Todas"}>Todas as modalidades</MenuItem>
                      {repassesOriginal && repassesOriginal.filter((option, index, self) => index === self.findIndex((o) => o.MD_IT === option.MD_IT)).map((option) => (
                        <MenuItem key={option.MD_IT} value={option.MD_IT}>
                          {option.MD_IT}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }

              </DialogContent>
            }
            <DialogActions style={{ paddingRight: 24 }}>
              {repasseSelecionado && <Button onClick={() => handleOpenWhatsapp(repasseSelecionado.TF_AN, repasseSelecionado.NM_IT)} style={{ textTransform: "none" }} color="success" variant="contained" startIcon={<WhatsApp style={{ color: "white", fontSize: 20 }} />}>Abrir Whatsapp</Button>}
              <Button onClick={handleCloseModal} style={{ textTransform: "none" }} color="error" variant="contained">Fechar</Button>
            </DialogActions>
          </>
        }
        <div style={{ height: 16 }} />

      </Dialog>

      <div style={{ padding: 26 }}>

        <div style={{ background: "white", display: "flex", alignItems: "center", height: 60, borderRadius: 8 }}>
          <div style={{ width: 16 }} />
          <Search style={{ fontSize: 28, color: "#888" }} />
          <div style={{ width: 16 }} />
          <TextField variant="standard"
            value={pesquisa}
            onChange={handlePesquisa}
            InputProps={{
              style: { fontSize: 18 },
              disableUnderline: true,
            }} />
          {!isMobile &&
            <>
              <div style={{ width: 16 }} />
              <Button variant="text" style={{ textTransform: "none", marginLeft: "auto" }} onClick={() => handleOpenFilter("local")} color="inherit" startIcon={<LocationOnOutlined style={{ color: "#ff9900" }} />} endIcon={<KeyboardArrowDown />}>
                {local && local !== "Todos" ? local : "Local"}
              </Button>
              <div style={{ width: 16 }} />
              <Button variant="text" style={{ textTransform: "none" }} color="inherit" onClick={() => handleOpenFilter("data")} startIcon={<CalendarMonthOutlined style={{ color: "#ff9900" }} />} endIcon={<KeyboardArrowDown />}>
                {data && data !== "Todas" ? formatarDataT(data * 1000) : "Todas as datas"}
              </Button>
              <div style={{ width: 16 }} />
              <Button variant="text" style={{ textTransform: "none" }} color="inherit" onClick={() => handleOpenFilter("modalidade")} startIcon={<WidgetsOutlined style={{ color: "#ff9900" }} />} endIcon={<KeyboardArrowDown />}>
                {modalidade && modalidade !== "Todas" ? modalidade : "Modalidade"}
              </Button>
              <div style={{ width: 16 }} />
              <Button variant="contained" disableElevation style={{ textTransform: "none" }} onClick={handleFiltrar} >
                Buscar
              </Button>
              <div style={{ width: 16 }} />
            </>
          }
        </div>

        {isMobile &&
          <>
            <div style={{ height: 16 }} />
            <div style={{ background: "white", display: "block", alignItems: "center", borderRadius: 8 }}>
              <div style={{ width: 16 }} />
              <Button variant="text" fullWidth style={{ textTransform: "none", marginLeft: "auto" }} onClick={() => handleOpenFilter("local")} color="inherit" startIcon={<LocationOnOutlined style={{ color: "#ff9900" }} />} endIcon={<KeyboardArrowDown />}>
                {local && local !== "Todos" ? local : "Local"}
              </Button>
              <Divider />
              <Button variant="text" fullWidth style={{ textTransform: "none" }} color="inherit" onClick={() => handleOpenFilter("data")} startIcon={<CalendarMonthOutlined style={{ color: "#ff9900" }} />} endIcon={<KeyboardArrowDown />}>
                {data && data !== "Todas" ? formatarDataT(data * 1000) : "Todas as datas"}
              </Button>
              <Divider />
              <Button variant="text" fullWidth style={{ textTransform: "none" }} color="inherit" onClick={() => handleOpenFilter("modalidade")} startIcon={<WidgetsOutlined style={{ color: "#ff9900" }} />} endIcon={<KeyboardArrowDown />}>
                {modalidade && modalidade !== "Todas" ? modalidade : "Modalidade"}
              </Button>

              <div style={{ width: 16 }} />
            </div>
            <div style={{ height: 16 }} />
            <Button variant="contained" fullWidth disableElevation style={{ textTransform: "none" }} onClick={handleFiltrar} >
              Buscar
            </Button>
          </>
        }
        <div style={{ height: 40 }} />

        <div style={{ display: isMobile ? "" : "flex" }}>
          <Tabs value={abaRepasse} onChange={handleChange} variant={isMobile ? "fullWidth" : ""} >
            <Tab label="Repasses" {...a11yProps(0)} style={{ textTransform: "none", borderBottom: "1px solid #CCC", width: 140 }} />
            <Tab label="Meus Repasses" disabled={!isLogged} {...a11yProps(1)} style={{ textTransform: "none", borderBottom: "1px solid #CCC", width: 140 }} />
          </Tabs>
          <Button variant="contained" disabled={!isLogged} disableElevation fullWidth={isMobile} style={{ textTransform: "none", marginLeft: "auto", fontWeight: "bold", marginTop: isMobile ? 16 : 0 }} onClick={handleAnunciar} >
            Anunciar Repasse
          </Button>
        </div>

        <div style={{ height: 14 }} />

        <Grid container spacing={0} style={{ width: '100%' }}>
          {repasses && repasses.map((repasse) => (
            // (abaRepasse === 1 || (abaRepasse === 0 && repasse.ST_IT !== "F")) &&
            ((abaRepasse === 1 && idUsuario === repasse.ID_USR) || (abaRepasse === 0 && repasse.ST_IT === "A")) &&
            <Grid item xs={12} sm={6} md={4} lg={3} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
              <Card sx={{ borderRadius: "8px" }}>
                <div style={{ padding: 16 }}>
                  <CardMedia
                    component="img"
                    height="170"
                    style={{ borderRadius: 8 }}
                    image={`https://esportizese.online/img-eventos/${repasse.ID_IT_PDT}.png?${new Date().getTime()}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }}
                  />
                  <CardContent style={{ textAlign: "left", padding: 0 }}>
                    <div style={{ height: 16 }} />
                    <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", wordWrap: "break-word" }}>
                      {repasse.NM_IT}
                    </Typography>

                    <div style={{ height: 8 }} />
                    <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap", fontSize: 14 }}>
                      <StraightenOutlined style={{ fontSize: 20 }} />
                      <div style={{ width: 6 }} />
                      Tamanho ({repasse.TM_IT})
                    </Typography>
                    <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", fontSize: 14 }}>
                      <CalendarMonthOutlined style={{ fontSize: 20 }} />
                      <div style={{ width: 6 }} />
                      {formatarDataHora(repasse.DT_IT * 1000)}
                    </Typography>
                    <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", fontSize: 14 }}>
                      <LocationOnOutlined style={{ fontSize: 20 }} />
                      <div style={{ width: 6 }} />
                      {repasse.LC_IT}
                    </Typography>
                    <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", fontSize: 14 }}>
                      <Route style={{ fontSize: 20 }} />
                      <div style={{ width: 6 }} />
                      {repasse.DSC_IT}
                    </Typography>
                    <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", fontSize: 14 }}>
                      <WidgetsOutlined style={{ fontSize: 20 }} />
                      <div style={{ width: 6 }} />
                      {repasse.MD_IT}
                    </Typography>
                    <div style={{ height: 12 }} />
                    <Divider />
                    {abaRepasse === 0 &&
                      <>
                        <div style={{ height: 12 }} />
                        <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center" }}>
                          <Avatar {...stringAvatar(repasse.NM_AN)} style={{ width: 30, height: 30, fontSize: 12, fontWeight: "400" }} />
                          <div style={{ width: 8 }} />
                          {getFirstName(repasse.NM_AN)}
                        </Typography>
                        <div style={{ height: 4 }} />
                        <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                          <IconButton style={{ width: 30, height: 30, background: "#2cd46b" }} onClick={() => handleOpenWhatsapp(repasse.TF_AN, repasse.NM_IT)} >
                            <WhatsApp style={{ color: "white", fontSize: 20 }} />
                          </IconButton>
                          <div style={{ width: 8 }} />
                          <label style={{ filter: isLogged ? "" : "blur(3px)", userSelect: "none", fontWeight: isLogged ? "" : "bold", color: isLogged ? "#ABACBA" : "#333" }}>{isLogged ? repasse.TF_AN : "(99) 99999-9999"}</label>
                          {isLogged ? "" : <Tooltip title="Para visualizar o telefone, realize o login ou crie uma conta." onClick={handleLogin}>&nbsp;&nbsp;&nbsp;<Visibility style={{ color: "#1e3a8a", cursor: "pointer", marginBottom: -3 }} /> </Tooltip>}
                        </Typography>
                      </>
                    }
                    {abaRepasse === 1 &&
                      <>
                        <div style={{ height: 12 }} />
                        <Typography style={{ color: "#555", fontFamily: "roboto regular", fontWeight: "bold", display: "flex", fontSize: 14 }}>
                          R$ {repasse.VL_IT}
                        </Typography>
                        <div style={{ height: 8 }} />
                        <Typography style={{ color: "#fff", fontFamily: "roboto regular", display: "flex", fontSize: 14, background: repasse.ST_IT === "F" ? "tomato" : repasse.ST_IT === "R" ? "#666699" : "green", borderRadius: 3, textAlign: "center", padding: 4, fontWeight: "bold" }}>
                          <div style={{ width: 6 }} />
                          {repasse.ST_IT === "F" && "Encerrada"}
                          {repasse.ST_IT === "R" && "Reservada"}
                          {repasse.ST_IT === "A" && "Anunciada"}
                        </Typography>
                      </>
                    }
                    <div style={{ height: 16 }} />
                    <Button variant="contained" color="primary" disabled={abaRepasse === 0 && repasse.ST_IT === "F"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }} onClick={() => handleRepasse(repasse)}>
                      {abaRepasse === 1 && "Editar"}
                      {abaRepasse === 0 && repasse.ST_IT === "F" && "Reservada"}
                      {abaRepasse === 0 && repasse.ST_IT !== "F" && <>R$ {repasse.VL_IT}</>}
                    </Button>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

      <SwipeableDrawer
        container={container}

        anchor="bottom"
        open={openSwipe}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div style={{ backgroundColor: "#B2B2B2", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

        {repasseSelecionado ?
          <>
            <Typography style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 22 }}>Editar Repasse</Typography>
          </>
          :
          <>
            <Typography style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 22 }}>Anunciar Repasse</Typography>
          </>
        }



        <Grid container spacing={0} style={{ width: "100%", paddingRight: 12, paddingLeft: 12 }}>

          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <div style={{ padding: 10, border: "dashed 2px #c7c7c7", borderRadius: 12, cursor: "pointer" }} onClick={handleUploadFile}>
              <CardMedia
                component="img"
                height="170"
                style={{ borderRadius: 12 }}
                image={selectedImage ? selectedImage : `https://esportizese.online/img-eventos/${repasseSelecionado.ID_IT_PDT}.png?${new Date().getTime()}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} />
          <Grid item xs={12} sm={12} md={4} lg={4} />
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Nome
            </Typography>
            <div style={{ height: 4 }} />
            <TextField
              fullWidth
              hiddenLabel
              value={nome}
              onChange={handleNome}
              variant="outlined"
              size="large"
              placeholder="Digite aqui seu nome"
              type="text"
            />
            <div style={{ height: 10 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Contato
            </Typography>
            <div style={{ height: 4 }} />
            <TextField
              fullWidth
              hiddenLabel
              value={celular}
              onChange={handleCelular}
              variant="outlined"
              size="large"
              placeholder="Digite aqui seu contato"
              inputProps={{ maxLength: 15 }}
              type="text"
            />
            <div style={{ height: 10 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Tamanho do KIT
            </Typography>
            <div style={{ height: 4 }} />
            <Select
              value={tamanho}
              onChange={handleTamanho}
              fullWidth
            >
              <MenuItem key={"P"} value={"P"}>P</MenuItem>
              <MenuItem key={"M"} value={"M"}>M</MenuItem>
              <MenuItem key={"G"} value={"G"}>G</MenuItem>
              <MenuItem key={"GG"} value={"GG"}>GG</MenuItem>
            </Select>
            <div style={{ height: 10 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Nome do evento
            </Typography>
            <div style={{ height: 4 }} />
            <TextField
              fullWidth
              hiddenLabel
              value={nomeEvento}
              onChange={handleNomeEvento}
              variant="outlined"
              size="large"
              placeholder="Digite aqui o nome do evento"
              type="text"
            />
            <div style={{ height: 10 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Distancia do evento
            </Typography>
            <div style={{ height: 4 }} />
            <TextField
              fullWidth
              hiddenLabel
              value={distancia}
              onChange={handleDistancia}
              variant="outlined"
              size="large"
              placeholder="Digite aqui a distancia do evento"
              type="text"
            />
            <div style={{ height: 10 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Valor
            </Typography>
            <div style={{ height: 4 }} />
            <TextField
              fullWidth
              hiddenLabel
              value={valor}
              onChange={handleValor}
              variant="outlined"
              size="large"
              placeholder="Digite aqui o valor de repasse"
              type="text"
            />
            <div style={{ height: 10 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Local do evento
            </Typography>
            <div style={{ height: 4 }} />
            <TextField
              fullWidth
              hiddenLabel
              value={localEvento}
              onChange={handleLocalEvento}
              variant="outlined"
              size="large"
              placeholder="Digite aqui o local do evento"
              type="text"
            />
            <div style={{ height: 10 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Data e hora do evento
            </Typography>
            <div style={{ height: 4 }} />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
              <DateTimePicker
                value={dataHora}
                onChange={(newValue) => setDataHora(newValue)}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY HH:mm" // Formato correto
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
            <div style={{ height: 10 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Modalidade
            </Typography>
            <div style={{ height: 4 }} />
            <Select
              value={modalidade}
              onChange={handleModalidade}
              fullWidth
            >
              <MenuItem key={"Corrida de rua"} value={"Corrida de rua"}>Corrida de rua</MenuItem>
            </Select>
            <div style={{ height: 10 }} />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Typography style={{ textAlign: "left", fontSize: "16px", fontWeight: "600" }}>
              Status
            </Typography>
            <div style={{ height: 4 }} />
            <Select
              value={status}
              onChange={handleStatus}
              fullWidth
            >
              <MenuItem key={"A"} value={"A"}>Anunciado</MenuItem>
              <MenuItem disabled={!repasseSelecionado} key={"R"} value={"R"}>Reservado</MenuItem>
              <MenuItem disabled={!repasseSelecionado} key={"F"} value={"F"}>Encerrada</MenuItem>
            </Select>
            <div style={{ height: 10 }} />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} />
          <Grid item xs={12} sm={6} md={4} lg={4} />

          <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
            <Button variant="contained" size="large" disableElevation fullWidth={isMobile} style={{ textTransform: "none", float: "right", fontWeight: "bold", marginTop: isMobile ? 16 : 0 }} onClick={handleSalvar} >
              {repasseSelecionado ? "Salvar" : "Anunciar"}
            </Button>
            <div style={{ height: 40 }} />
          </Grid>

        </Grid>

        <div style={{ height: 22 }} />
      </SwipeableDrawer>

    </ThemeProvider >
  );
}

export default Repasses;